import React from "react"

import Layout from "components/Layout/en"
import { Container, Row, Col } from "react-bootstrap"
// import Image from "components/Image/index"
import style from "styles/global.module.scss"

// import partner images/logos
import team from "./partners/team.jpg"
import audiLogo from "./partners/audi.png"
import mibaLogo from "./partners/miba.png"
import vodafoneLogo from "./partners/vodafone.png"

// import videos
import bearingVideo from "./videos/bearing.mp4"
import rustVideo from "./videos/rust.mp4"
import streetVideo from "./videos/street.mp4"
import woodVideo from "./videos/wood.mp4"

export default function(props) {
  const header = {
    text: "MoonVision Use Cases",
    description:
      "MoonVision Use Cases in Automotive and Wood Industry and Quality Assurance",
  }
  return (
    <Layout header={header} location={props.location}>
      <main className="white-background">
        <section className={style.stripeSectors}>
          <Container>
            <Row className="py-md-4">
              <Col md="6" className="order-md-last">
                <video autoPlay loop muted>
                  <source src={streetVideo} type="video/mp4" />
                </video>
              </Col>
              <Col md="6">
                <h2>
                  <small className="text-muted">Automotive Industry</small>
                  <br />
                  Inspect street conditions with surface analysis for smarter
                  city planning
                </h2>
                <p>
                  Vodafone Germany uses the Moonvision Toolbox for street
                  surface inspection, defect detection and maintainance
                  optimization. By detecting cracks and pot holes early Vodafone
                  enables cities to better plan their maintanence work and keep
                  their infrastructure in shape.
                </p>
                <p>
                  In Partnership with:
                  <br />
                  <img src={vodafoneLogo} width="120" alt="Logo Vodafone" />
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={style.stripeSectors}>
          <Container>
            <Row className="py-md-4">
              <Col md="6">
                <video autoPlay loop muted>
                  <source src={woodVideo} type="video/mp4" />
                </video>
              </Col>
              <Col md="6">
                <h2>
                  <small className="text-muted">Wood Industry </small>
                  <br />
                  Classification and Quality Inspection
                </h2>
                <p>
                  We ensure that wood processing companies only process the
                  right wood quality.
                  <br />
                  The MoonVision software can be customized to your needs and
                  supports you reliably with a fully automated recognition
                  process.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={style.stripeSectors}>
          <Container>
            <Row className="py-md-4">
              <Col md="6" className="order-md-last">
                <video autoPlay loop muted>
                  <source src={rustVideo} type="video/mp4" />
                </video>
              </Col>
              <Col md="6">
                <h2>
                  <small className="text-muted">Quality Assurance</small>
                  <br />
                  Rust Detection Benchmark
                </h2>
                <p>
                  A metal producer analyses used metal pieces for quality
                  features in the utilisation process.
                </p>
                <p>Sample Size:</p>
                <ul>
                  <li>11 Images only</li>
                  <li>
                    <a
                      href="https://www.invision-news.de/neuheiten/oberflaechenerkennung-mittels-ai/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Invision Article
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={style.stripeSectors}>
          <Container>
            <Row className="py-md-4">
              <Col md="6">
                <video autoPlay loop muted>
                  <source src={bearingVideo} type="video/mp4" />
                </video>
              </Col>
              <Col md="6">
                <h2>
                  <small className="text-muted">Quality Assurance</small>
                  <br />
                  Bearing Surface Analysis
                </h2>
                <p>
                  Miba used the toolbox to challenge its experts in surface
                  analysis and defect evaluation tasks for the bearing servicing
                  process and trained the system to detect better.
                </p>
                <p>
                  In Partnership with:
                  <br />
                  <img src={mibaLogo} width="80" alt="logo miba" />
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className={style.stripeSectors}>
          <Container>
            <Row className="py-md-4">
              <Col md={{ span: 6, order: "last" }}>
                <img src={team} alt="hackathon team" />
              </Col>
              <Col md="6">
                <h2>
                  <small className="text-muted">Automotive Industry</small>
                  <br />
                  Automated Container Detection
                </h2>
                <p>
                  Audi Logistics uses the toolbox for automated recognition of
                  transport containers with cameras on their forklifts. This
                  allows a realtime inventory of the current container
                  quantities on the different storage spaces in Ingolstadt.
                </p>
                <p>
                  In Partnership with:
                  <br />
                  <img src={audiLogo} width="100" alt="audi logo" />
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
    </Layout>
  )
}
